import type { RouteLocationNormalized, RouteRecordName } from 'vue-router'
import { useAppConfiguration } from '~/stores/appConfiguration'
import { useWorkspace } from '~/stores/workspace'

const getSignInRedirectPath = (
  to: RouteLocationNormalized,
  windowLocation: Location,
) => {
  // return navigateTo(`/signin?from=${encodeURIComponent(to.path)}`, { replace: true })
  // dropping down to use location to redirect. navigateTo stalls when used client side
  if (to.name === 'index') {
    windowLocation.replace('/signin')
    return
  }

  const fromUrl = new URL(windowLocation.href)
  fromUrl.pathname = '/signin'

  const allowedReturnPageNames: RouteRecordName[] = [
    'changelog',
    'claims',
    'claims-id',
    'claims-id-insightId-influencing-claims',
    'dashboard',
    'exclusions',
    'exclusions-id',
    'policies',
    'policies-id',
    'policies-id-providers-impacted',
    'account-api-clients',
    'terms',
  ]

  if (to.name && allowedReturnPageNames.includes(to.name)) {
    const query = new URLSearchParams(to.query as Record<string, string>)
    const path = query.size ? `${to.path}?${query.toString()}` : to.path
    fromUrl.search = new URLSearchParams({
      from: encodeURIComponent(path),
    }).toString()
  }

  windowLocation.replace(fromUrl)
  return
}

export default defineNuxtRouteMiddleware(async (to) => {
  const { $auth, $auth0, $auth0Ready, $datadog, $pinia } = useNuxtApp()
  const currentUser = useCurrentUser()
  const workspace = useWorkspace($pinia)

  if (to.name === 'welcome') {
    currentUser.value = await workspace.getUser()
    return
  }

  if (to.meta.public) {
    return
  }

  const tenantConfig = useAppConfig().tenant

  if (process.client) {
    const { isAuthenticated, user } = $auth0()

    await $auth0Ready()

    if (!isAuthenticated.value && tenantConfig.supportCognitoAuth) {
      let accessToken = sessionStorage.getItem('@rialtic/accessToken')

      if (!accessToken) {
        // Give the localStorage token sync time to complete
        await new Promise((resolve) => setTimeout(() => resolve(true), 100))
        accessToken = sessionStorage.getItem('@rialtic/accessToken')
      }

      if (!accessToken) {
        return getSignInRedirectPath(to, location)
      }

      try {
        await $auth.checkAndRefresh()
      } catch (error) {
        $datadog.addAction('redirect_to_sign_in', { error })
        return getSignInRedirectPath(to, location)
      }

      try {
        const profile = await $auth.getProfile()

        if (!profile) {
          throw new Error('Error getting profile')
        }
      } catch (error) {
        workspace.user = null
        $datadog.addAction('redirect_to_sign_in', { error })
        return getSignInRedirectPath(to, location)
      }
    } else if (!isAuthenticated.value) {
      return navigateTo('/auth/login')
    }

    try {
      const connectorId = to.query.lob as string

      currentUser.value = await workspace.getUser(connectorId)

      const appConfig = useAppConfiguration()

      await appConfig.getAll()

      const isRialtic =
        currentUser.value?.globalRoles['@rialitc'] ||
        currentUser.value?.globalRoles['content@rialtic.io'] ||
        user.value?.['https://auth.rialtic.io/roles'].includes('@rialtic')

      if (appConfig.maintenance && to.name !== 'welcome' && !isRialtic) {
        return navigateTo('/maintenance')
      }

      const hasConsoleViewPermission = [
        ...((currentUser.value?.permissions as string[]) ?? []),
      ].some((p) => p.endsWith('read:connector-config'))
      const canAccessConsole: boolean =
        !!Object.keys(currentUser.value?.workspaceRoles || {}).length ||
        (!!Object.keys(currentUser.value?.workspaces || {}).length &&
          hasConsoleViewPermission)

      if (!canAccessConsole) {
        return navigateTo('/welcome')
      }
      if (!currentUser.value?.connectors.length) {
        if (/^account/.test(to.name as string)) {
          return
        }
        return navigateTo('/account')
      }
    } catch (error) {
      return getSignInRedirectPath(to, location)
    }
  }
})
