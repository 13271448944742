import { useIdle } from '@vueuse/core'

import { useAuthorization } from './authorization'

export default defineNuxtPlugin(({ $pinia, $router }) => {
  if (!$pinia) {
    if (process.dev) {
      console.error('Requires nuxt/pinia')
    }
    return
  }

  const auth = useAuthorization($pinia)

  const { idle } = useIdle(15 * 60 * 1000) // 15 min

  watch(idle, (isIdle, prevIsIdle) => {
    if (!isIdle && prevIsIdle) {
      try {
        auth.checkAndRefresh()
      } catch (error) {
        $router.push('/signin')
      }
    }
  })

  if (process.client) {
    const syncKey = `${location.origin}:sync`

    if (!sessionStorage.length) {
      // Ask other tabs for session storage
      localStorage.setItem(syncKey, `${location.origin}:${Date.now()}`)
    }

    window.addEventListener('storage', (event) => {
      if (event.key === syncKey) {
        console.log('Session token requested')
        // Some tab asked for the sessionStorage -> send it
        localStorage.setItem('sessionStorage', JSON.stringify(sessionStorage))
        localStorage.removeItem('sessionStorage')
      } else if (event.key == 'sessionStorage' && !sessionStorage.length) {
        // sessionStorage is empty -> fill it
        const data = JSON.parse(event.newValue)
        for (let key in data) {
          sessionStorage.setItem(key, data[key])
        }
      }
    })
  }

  return {
    provide: {
      auth,
    },
  }
})
