import routerOptions0 from "/builds/rialtic/workflows/console-ui/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_encoding@0.1.13_idb-keyval@6.2.1_ioredi_g2d7tbfeghjxc43ksbzjch4y2m/node_modules/nuxt/dist/pages/runtime/router.options";
import routerOptions1 from "/builds/rialtic/workflows/console-ui/apps/console/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}