import type { DatePickerRange, DropDownItem } from '@rialtic/types'
import { closestTo, subDays } from 'date-fns'
import { datePresets, dateRangePattern, sinceInceptionDate } from '~/constants'
import { useWorkspace } from '~/stores/workspace'

export function useDatePresets() {
  const workspace = useWorkspace()
  const inceptionDate = computed(() => {
    return workspace.activeConnector
      ? workspace.activeConnector.inception_date
      : '2001-01-01T00:00:00-05:00'
  })
  const currentPresetDate = ref(new Date())
  const currentYear = computed(() => currentPresetDate.value.getFullYear())
  const quarterStartDates = [
    new Date(`${currentYear.value}-01-01`),
    new Date(`${currentYear.value}-04-01`),
    new Date(`${currentYear.value}-07-01`),
    new Date(`${currentYear.value}-10-01`),
  ]

  /**
   * Gets Date Range where Start Date is offset by number of provided days from Current Date
   * @param currentDate Provides current date and supports reactivity
   * @param days Days to offset backwards from Current Date
   * @param preset Name of preset to be returned in new DatePickerRange
   * @returns {DatePickerRange}
   */
  const getDateRangeOffsetByDays = (
    currentDate: Date,
    days: number,
    preset: string,
  ): DatePickerRange => {
    return {
      // We need to subtract a day for the current day since we include it in the range
      start: formatDisplayDate(
        subDays(currentDate, days - 1),
        dateRangePattern,
      ),
      end: formatDisplayDate(currentDate, dateRangePattern),
      preset,
    }
  }

  /**
   * Gets Date Range starting with closest Quarter Date to Current Date
   * @param currentDate Provides current date and supports reactivity
   * @returns {DatePickerRange}
   */
  const getQuarterToDateRange = (currentDate: Date): DatePickerRange => {
    const closestQuarterStartDate = closestTo(currentDate, quarterStartDates)

    return {
      start: formatDisplayDate(closestQuarterStartDate, dateRangePattern),
      end: formatDisplayDate(currentDate, dateRangePattern),
      preset: datePresets.quarterToDate,
    }
  }

  /**
   * Gets Date Range from first of the year to Current Date
   * @param currentDate Provides current date and supports reactivity
   * @returns {DatePickerRange}
   */
  const getYearToDateRange = (currentDate: Date): DatePickerRange => {
    return {
      start: formatDisplayDate(`${currentYear.value}-01-01`, dateRangePattern),
      end: formatDisplayDate(currentDate, dateRangePattern),
      preset: datePresets.yearToDate,
    }
  }

  /**
   * Gets Date Range from Active Connector created_at Date to Current Date
   * @param currentDate Provides current date and supports reactivity
   * @param dateValue Date value to format and return as part of DatePickerRange
   * @returns {DatePickerRange}
   */
  const getInceptionDateRange = (
    currentDate: Date,
    dateValue: string,
  ): DatePickerRange => {
    const inceptionStartDate = dateValue
      ? new Date(dateValue)
      : sinceInceptionDate

    return {
      start: formatDisplayDate(inceptionStartDate, dateRangePattern),
      end: formatDisplayDate(currentDate, dateRangePattern),
      preset: datePresets.sinceInception,
    }
  }

  /**
   * Gets preset from provided preset name
   * @param presetName Name of preset
   * @returns {DropDownItem}
   */
  const getPresetByName = (presetName: string): DropDownItem => {
    return presets.value.find((preset) => preset.label === presetName)!
  }

  /**
   * Preset Dates used in Date Picker
   * @type {DropDownItem[]}
   */
  const presets = computed(() => [
    {
      label: datePresets.lastSeven,
      itemValue: getDateRangeOffsetByDays(
        currentPresetDate.value,
        7,
        datePresets.lastSeven,
      ),
    },
    {
      label: datePresets.lastThirty,
      itemValue: getDateRangeOffsetByDays(
        currentPresetDate.value,
        30,
        datePresets.lastThirty,
      ),
    },
    {
      label: datePresets.lastNinety,
      itemValue: getDateRangeOffsetByDays(
        currentPresetDate.value,
        90,
        datePresets.lastNinety,
      ),
    },
    {
      label: datePresets.quarterToDate,
      itemValue: getQuarterToDateRange(currentPresetDate.value),
    },
    {
      label: datePresets.yearToDate,
      itemValue: getYearToDateRange(currentPresetDate.value),
    },
    {
      label: datePresets.sinceInception,
      itemValue: getInceptionDateRange(
        currentPresetDate.value,
        inceptionDate.value,
      ),
    },
    { label: datePresets.custom, itemValue: {} as DatePickerRange },
  ])

  return {
    currentPresetDate,
    getPresetByName,
    presets,
  }
}
