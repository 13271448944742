import type { DropDownItem } from '@rialtic/types'

// types/enums
export const enum FilterQueryNames {
  SourceTypeLevelOne = 'source_type_level_1',
  Region = 'region',
}

export const enum RegionTypeNames {
  Jurisdiction = 'jurisdiction',
  State = 'state',
}

// general constants
export const ALL_LOB = <const>'all_lob'
export const RESULTS_LIMIT = <const>100

export const datePresets = <const>{
  lastSeven: 'Last 7 days',
  lastThirty: 'Last 30 days',
  lastNinety: 'Last 90 days',
  quarterToDate: 'Quarter to date',
  yearToDate: 'Year to date',
  sinceInception: 'Since inception',
  custom: 'Custom',
}

export const dateRangePattern = <const>'yyyy-MM-dd'
export const dateRangePatternAlt = <const>'MM/dd/yy'

//TODO: change to enum in lib
export const draftOperations = <const>{
  create: 'create',
  edit: 'edit',
  delete: 'delete',
}
export const draftOptions: DropDownItem[] = [
  {
    label: 'Create this exclusion',
    itemValue: draftOperations.create,
  },
  {
    label: 'Delete draft',
    itemValue: draftOperations.delete,
  },
  {
    label: 'Modify & review draft',
    itemValue: draftOperations.edit,
  },
]

//TODO: replace with enum in lib
export const exclusionStatus = {
  Archived: 'Archived',
  Draft: 'Draft',
  NotRunning: 'Not Running',
  Running: 'Running',
  Stopped: 'Stopped',
}

export const maxMilliseconds = <const>8640000000000000

export const lcaSourceFilter = <const>{
  id: '53b35ed3-d623-48e8-a93d-84ee54dfc998',
  name: 'Local Coverage Article/Determinations (LCA/LCD)',
}

export const policyFilterOrder = <const>[
  'insight_mode',
  'topic',
  'edit_type',
  'source_type_level_1',
  'region',
]

export const scheduleTimeDefault = '00:01'

export const sinceInceptionDate = <const>'2001-01-01'

export const sortColumnMapExclusions = <const>{
  category: 'category.name',
  claim_line_type: 'claim_line_type',
  effective_by: 'effective_by',
  effective_date_start: 'effective_date_start',
  effective_date_end: 'effective_date_end',
  impacted_claim_count: 'impact.claim_count',
  min_savings_opportunity: 'impact.min_savings',
  max_savings_opportunity: 'impact.max_savings',
  name: 'name',
  status: 'status',
}

export const sortColumnMapPolicies = <const>{
  impacted_providers: 'insight.providers_impacted',
  insight_count_active: 'insight.insight_count_active',
  insight_count_observation: 'insight.insight_count_observation',
  name: 'policy.name',
  savings_active: 'insight.savings_active',
  savings_observation: 'insight.savings_observation',
  status: 'insight_mode',
  // TODO: Remove when Feature Flag removed
  insight_count: 'insight.count',
  savings: 'insight.savings_opportunity',
  // END Feature Flag to be removed
}
