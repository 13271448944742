import { defu } from 'defu'
import { useAppConfiguration } from '~/stores/appConfiguration'
import { useWorkspace } from '~/stores/workspace'

export default defineNuxtRouteMiddleware(async (to, from) => {
  const appConfig = useAppConfiguration()
  const workspace = useWorkspace()

  const config = await $fetch('/api/config')

  if (!config?.tenant.supportCognitoAuth) {
    switch (to.name) {
      case 'signin':
      case 'signup':
      case 'signup-confirm':
        return navigateTo('/auth/login', { replace: true })
      default:
        break
    }
  }

  if (process.client) {
    const appConfig = useAppConfig()

    appConfig.tenant = defu(config?.tenant ?? {}, appConfig.tenant)
    appConfig.host = config?.host
    appConfig.version = config?.version
  }

  if (
    to.name === 'changelog' &&
    process.client &&
    !(workspace.isRialticAdmin || appConfig.feature_nonadmin_changelog)
  ) {
    return abortNavigation()
  }

  // this is masking a significant bug with formkit
  if (
    to.name === 'exclusions-id-edit' &&
    from.name === 'exclusions-id-edit' &&
    !to.redirectedFrom
  ) {
    return navigateTo(`/exclusions`)
  }

  if (
    to.name === 'claims-id' &&
    to.path.includes('Claim-') &&
    appConfig.feature_claim_id_redirect
  ) {
    return navigateTo(to.path.replace('Claim-', ''))
  }
})
