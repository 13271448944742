import { default as _91claimId_93LCEjLQpWk3Meta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue?macro=true";
import { default as index09IO1qV1BKMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue?macro=true";
import { default as _91connectorId_93pPQASykoTaMeta } from "/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue?macro=true";
import { default as api_45clientsSrLAyDYKsxMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/account/api-clients.vue?macro=true";
import { default as indexqncYKthOhAMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/account/index.vue?macro=true";
import { default as usersJOJU2AKATOMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/account/users.vue?macro=true";
import { default as api_45adminKygpjikY03Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/api-admin.vue?macro=true";
import { default as app_45configSc8CwSVdSmMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/app-config.vue?macro=true";
import { default as create_45workspace2NcLbKQ1uaMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index/create-workspace.vue?macro=true";
import { default as _91id_93sdEXtaa5amMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index/edit-workspace/[id].vue?macro=true";
import { default as indexyN4kpyFgDBMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index.vue?macro=true";
import { default as _91id_93xNU4PMkOXtMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/[id].vue?macro=true";
import { default as createAuyz77TXG2Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/create.vue?macro=true";
import { default as indexV5zCQl1uN8Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/index.vue?macro=true";
import { default as usersDp3wiRptUjMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users.vue?macro=true";
import { default as _91connId_93UkXhE8arLBMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue?macro=true";
import { default as indexvtZCOlS7RiMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue?macro=true";
import { default as editYecJYFZmFOMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue?macro=true";
import { default as indexFnGp71Oz9VMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index.vue?macro=true";
import { default as _91id_93YpdYY0BZ6SMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id].vue?macro=true";
import { default as index8GUmIU5CGAMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/index.vue?macro=true";
import { default as app_45statusp1k6BaByKaMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/app-status.vue?macro=true";
import { default as changelogDeGUtbsgi4Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/changelog.vue?macro=true";
import { default as influencing_45claims0DcciAw2jEMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue?macro=true";
import { default as _91id_93d7ZaMKETf9Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id].vue?macro=true";
import { default as claimsGfnwZqok0GMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/claims.vue?macro=true";
import { default as dashboardDRDY6ZNtKEMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/dashboard.vue?macro=true";
import { default as edit4etmseOF4nMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/edit.vue?macro=true";
import { default as indexh5w8XyRNDgMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/index.vue?macro=true";
import { default as createfxDKV9lyIaMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/create.vue?macro=true";
import { default as indextvt0JtqxyGMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/index.vue?macro=true";
import { default as exclusionsA8FEcyWzdlMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions.vue?macro=true";
import { default as indexDWCwiLditCMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/index.vue?macro=true";
import { default as maintenanceud8cPGJobNMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/maintenance.vue?macro=true";
import { default as providers_45impactedgNf0Cq2yFdMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue?macro=true";
import { default as _91id_93uCPmH7mj50Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id].vue?macro=true";
import { default as indexfUb6sZlj7mMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies/index.vue?macro=true";
import { default as policiestS6TC3Xuq5Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/policies.vue?macro=true";
import { default as reset_45password_45confirm7rBPdaUyyGMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password-confirm.vue?macro=true";
import { default as reset_45passwordwFwPnE786bMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password.vue?macro=true";
import { default as signinBNCRKvIQTKMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signin.vue?macro=true";
import { default as confirm0KkTXJKgZ9Meta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signup/confirm.vue?macro=true";
import { default as indexT5S6KNtwKnMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signup/index.vue?macro=true";
import { default as signupIylKkg2SqPMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/signup.vue?macro=true";
import { default as welcomeimaWIc1gaTMeta } from "/builds/rialtic/workflows/console-ui/apps/console/pages/welcome.vue?macro=true";
import { default as auth_45callbackvzPvcsneV7Meta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue?macro=true";
import { default as auth_45login2NyvXp0YlaMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue?macro=true";
import { default as auth_45statustPEZIB9azrMeta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue?macro=true";
import { default as samlR4ARVeTuD4Meta } from "/builds/rialtic/workflows/console-ui/packages/nuxt-auth/src/pages/saml.vue?macro=true";
export default [
  {
    name: _91connectorId_93pPQASykoTaMeta?.name ?? "connectorId",
    path: _91connectorId_93pPQASykoTaMeta?.path ?? "/:connectorId()",
    meta: _91connectorId_93pPQASykoTaMeta || {},
    alias: _91connectorId_93pPQASykoTaMeta?.alias || [],
    redirect: _91connectorId_93pPQASykoTaMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId].vue").then(m => m.default || m),
    children: [
  {
    name: _91claimId_93LCEjLQpWk3Meta?.name ?? "connectorId-claim-lab-claimId",
    path: _91claimId_93LCEjLQpWk3Meta?.path ?? "claim-lab/:claimId()",
    meta: _91claimId_93LCEjLQpWk3Meta || {},
    alias: _91claimId_93LCEjLQpWk3Meta?.alias || [],
    redirect: _91claimId_93LCEjLQpWk3Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/claim-lab/pages/[connectorId]/claim-lab/[claimId].vue").then(m => m.default || m)
  },
  {
    name: index09IO1qV1BKMeta?.name ?? "connectorId-claim-lab",
    path: index09IO1qV1BKMeta?.path ?? "claim-lab",
    meta: index09IO1qV1BKMeta || {},
    alias: index09IO1qV1BKMeta?.alias || [],
    redirect: index09IO1qV1BKMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/[connectorId]/claim-lab/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: api_45clientsSrLAyDYKsxMeta?.name ?? "account-api-clients",
    path: api_45clientsSrLAyDYKsxMeta?.path ?? "/account/api-clients",
    meta: api_45clientsSrLAyDYKsxMeta || {},
    alias: api_45clientsSrLAyDYKsxMeta?.alias || [],
    redirect: api_45clientsSrLAyDYKsxMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/account/api-clients.vue").then(m => m.default || m)
  },
  {
    name: indexqncYKthOhAMeta?.name ?? "account",
    path: indexqncYKthOhAMeta?.path ?? "/account",
    meta: indexqncYKthOhAMeta || {},
    alias: indexqncYKthOhAMeta?.alias || [],
    redirect: indexqncYKthOhAMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: usersJOJU2AKATOMeta?.name ?? "account-users",
    path: usersJOJU2AKATOMeta?.path ?? "/account/users",
    meta: usersJOJU2AKATOMeta || {},
    alias: usersJOJU2AKATOMeta?.alias || [],
    redirect: usersJOJU2AKATOMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/account/users.vue").then(m => m.default || m)
  },
  {
    name: api_45adminKygpjikY03Meta?.name ?? "admin-api-admin",
    path: api_45adminKygpjikY03Meta?.path ?? "/admin/api-admin",
    meta: api_45adminKygpjikY03Meta || {},
    alias: api_45adminKygpjikY03Meta?.alias || [],
    redirect: api_45adminKygpjikY03Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/api-admin.vue").then(m => m.default || m)
  },
  {
    name: app_45configSc8CwSVdSmMeta?.name ?? "admin-app-config",
    path: app_45configSc8CwSVdSmMeta?.path ?? "/admin/app-config",
    meta: app_45configSc8CwSVdSmMeta || {},
    alias: app_45configSc8CwSVdSmMeta?.alias || [],
    redirect: app_45configSc8CwSVdSmMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/app-config.vue").then(m => m.default || m)
  },
  {
    name: indexyN4kpyFgDBMeta?.name ?? "admin",
    path: indexyN4kpyFgDBMeta?.path ?? "/admin",
    meta: indexyN4kpyFgDBMeta || {},
    alias: indexyN4kpyFgDBMeta?.alias || [],
    redirect: indexyN4kpyFgDBMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index.vue").then(m => m.default || m),
    children: [
  {
    name: create_45workspace2NcLbKQ1uaMeta?.name ?? "admin-index-create-workspace",
    path: create_45workspace2NcLbKQ1uaMeta?.path ?? "create-workspace",
    meta: create_45workspace2NcLbKQ1uaMeta || {},
    alias: create_45workspace2NcLbKQ1uaMeta?.alias || [],
    redirect: create_45workspace2NcLbKQ1uaMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index/create-workspace.vue").then(m => m.default || m)
  },
  {
    name: _91id_93sdEXtaa5amMeta?.name ?? "admin-index-edit-workspace-id",
    path: _91id_93sdEXtaa5amMeta?.path ?? "edit-workspace/:id()",
    meta: _91id_93sdEXtaa5amMeta || {},
    alias: _91id_93sdEXtaa5amMeta?.alias || [],
    redirect: _91id_93sdEXtaa5amMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/index/edit-workspace/[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersDp3wiRptUjMeta?.name ?? undefined,
    path: usersDp3wiRptUjMeta?.path ?? "/admin/users",
    meta: usersDp3wiRptUjMeta || {},
    alias: usersDp3wiRptUjMeta?.alias || [],
    redirect: usersDp3wiRptUjMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93xNU4PMkOXtMeta?.name ?? "admin-users-id",
    path: _91id_93xNU4PMkOXtMeta?.path ?? ":id()",
    meta: _91id_93xNU4PMkOXtMeta || {},
    alias: _91id_93xNU4PMkOXtMeta?.alias || [],
    redirect: _91id_93xNU4PMkOXtMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/[id].vue").then(m => m.default || m)
  },
  {
    name: createAuyz77TXG2Meta?.name ?? "admin-users-create",
    path: createAuyz77TXG2Meta?.path ?? "create",
    meta: createAuyz77TXG2Meta || {},
    alias: createAuyz77TXG2Meta?.alias || [],
    redirect: createAuyz77TXG2Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/create.vue").then(m => m.default || m)
  },
  {
    name: indexV5zCQl1uN8Meta?.name ?? "admin-users",
    path: indexV5zCQl1uN8Meta?.path ?? "",
    meta: indexV5zCQl1uN8Meta || {},
    alias: indexV5zCQl1uN8Meta?.alias || [],
    redirect: indexV5zCQl1uN8Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93YpdYY0BZ6SMeta?.name ?? undefined,
    path: _91id_93YpdYY0BZ6SMeta?.path ?? "/admin/workspace/:id()",
    meta: _91id_93YpdYY0BZ6SMeta || {},
    alias: _91id_93YpdYY0BZ6SMeta?.alias || [],
    redirect: _91id_93YpdYY0BZ6SMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexFnGp71Oz9VMeta?.name ?? "admin-workspace-id",
    path: indexFnGp71Oz9VMeta?.path ?? "",
    meta: indexFnGp71Oz9VMeta || {},
    alias: indexFnGp71Oz9VMeta?.alias || [],
    redirect: indexFnGp71Oz9VMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index.vue").then(m => m.default || m),
    children: [
  {
    name: _91connId_93UkXhE8arLBMeta?.name ?? "admin-workspace-id-index-connector-connId",
    path: _91connId_93UkXhE8arLBMeta?.path ?? "connector/:connId()",
    meta: _91connId_93UkXhE8arLBMeta || {},
    alias: _91connId_93UkXhE8arLBMeta?.alias || [],
    redirect: _91connId_93UkXhE8arLBMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/[connId].vue").then(m => m.default || m)
  },
  {
    name: indexvtZCOlS7RiMeta?.name ?? "admin-workspace-id-index-connector",
    path: indexvtZCOlS7RiMeta?.path ?? "connector",
    meta: indexvtZCOlS7RiMeta || {},
    alias: indexvtZCOlS7RiMeta?.alias || [],
    redirect: indexvtZCOlS7RiMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/connector/index.vue").then(m => m.default || m)
  },
  {
    name: editYecJYFZmFOMeta?.name ?? "admin-workspace-id-index-edit",
    path: editYecJYFZmFOMeta?.path ?? "edit",
    meta: editYecJYFZmFOMeta || {},
    alias: editYecJYFZmFOMeta?.alias || [],
    redirect: editYecJYFZmFOMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/[id]/index/edit.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: index8GUmIU5CGAMeta?.name ?? "admin-workspace",
    path: index8GUmIU5CGAMeta?.path ?? "/admin/workspace",
    meta: index8GUmIU5CGAMeta || {},
    alias: index8GUmIU5CGAMeta?.alias || [],
    redirect: index8GUmIU5CGAMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/admin/workspace/index.vue").then(m => m.default || m)
  },
  {
    name: app_45statusp1k6BaByKaMeta?.name ?? "app-status",
    path: app_45statusp1k6BaByKaMeta?.path ?? "/app-status",
    meta: app_45statusp1k6BaByKaMeta || {},
    alias: app_45statusp1k6BaByKaMeta?.alias || [],
    redirect: app_45statusp1k6BaByKaMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/app-status.vue").then(m => m.default || m)
  },
  {
    name: changelogDeGUtbsgi4Meta?.name ?? "changelog",
    path: changelogDeGUtbsgi4Meta?.path ?? "/changelog",
    meta: changelogDeGUtbsgi4Meta || {},
    alias: changelogDeGUtbsgi4Meta?.alias || [],
    redirect: changelogDeGUtbsgi4Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/changelog.vue").then(m => m.default || m)
  },
  {
    name: claimsGfnwZqok0GMeta?.name ?? "claims",
    path: claimsGfnwZqok0GMeta?.path ?? "/claims",
    meta: claimsGfnwZqok0GMeta || {},
    alias: claimsGfnwZqok0GMeta?.alias || [],
    redirect: claimsGfnwZqok0GMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/claims.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93d7ZaMKETf9Meta?.name ?? "claims-id",
    path: _91id_93d7ZaMKETf9Meta?.path ?? ":id()",
    meta: _91id_93d7ZaMKETf9Meta || {},
    alias: _91id_93d7ZaMKETf9Meta?.alias || [],
    redirect: _91id_93d7ZaMKETf9Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id].vue").then(m => m.default || m),
    children: [
  {
    name: influencing_45claims0DcciAw2jEMeta?.name ?? "claims-id-insightId-influencing-claims",
    path: influencing_45claims0DcciAw2jEMeta?.path ?? ":insightId()/influencing-claims",
    meta: influencing_45claims0DcciAw2jEMeta || {},
    alias: influencing_45claims0DcciAw2jEMeta?.alias || [],
    redirect: influencing_45claims0DcciAw2jEMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/claims/[id]/[insightId]/influencing-claims.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: dashboardDRDY6ZNtKEMeta?.name ?? "dashboard",
    path: dashboardDRDY6ZNtKEMeta?.path ?? "/dashboard",
    meta: dashboardDRDY6ZNtKEMeta || {},
    alias: dashboardDRDY6ZNtKEMeta?.alias || [],
    redirect: dashboardDRDY6ZNtKEMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: exclusionsA8FEcyWzdlMeta?.name ?? undefined,
    path: exclusionsA8FEcyWzdlMeta?.path ?? "/exclusions",
    meta: exclusionsA8FEcyWzdlMeta || {},
    alias: exclusionsA8FEcyWzdlMeta?.alias || [],
    redirect: exclusionsA8FEcyWzdlMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions.vue").then(m => m.default || m),
    children: [
  {
    name: edit4etmseOF4nMeta?.name ?? "exclusions-id-edit",
    path: edit4etmseOF4nMeta?.path ?? ":id()/edit",
    meta: edit4etmseOF4nMeta || {},
    alias: edit4etmseOF4nMeta?.alias || [],
    redirect: edit4etmseOF4nMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexh5w8XyRNDgMeta?.name ?? "exclusions-id",
    path: indexh5w8XyRNDgMeta?.path ?? ":id()",
    meta: indexh5w8XyRNDgMeta || {},
    alias: indexh5w8XyRNDgMeta?.alias || [],
    redirect: indexh5w8XyRNDgMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: createfxDKV9lyIaMeta?.name ?? "exclusions-create",
    path: createfxDKV9lyIaMeta?.path ?? "create",
    meta: createfxDKV9lyIaMeta || {},
    alias: createfxDKV9lyIaMeta?.alias || [],
    redirect: createfxDKV9lyIaMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/create.vue").then(m => m.default || m)
  },
  {
    name: indextvt0JtqxyGMeta?.name ?? "exclusions",
    path: indextvt0JtqxyGMeta?.path ?? "",
    meta: indextvt0JtqxyGMeta || {},
    alias: indextvt0JtqxyGMeta?.alias || [],
    redirect: indextvt0JtqxyGMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/exclusions/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexDWCwiLditCMeta?.name ?? "index",
    path: indexDWCwiLditCMeta?.path ?? "/",
    meta: indexDWCwiLditCMeta || {},
    alias: indexDWCwiLditCMeta?.alias || [],
    redirect: indexDWCwiLditCMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/index.vue").then(m => m.default || m)
  },
  {
    name: maintenanceud8cPGJobNMeta?.name ?? "maintenance",
    path: maintenanceud8cPGJobNMeta?.path ?? "/maintenance",
    meta: maintenanceud8cPGJobNMeta || {},
    alias: maintenanceud8cPGJobNMeta?.alias || [],
    redirect: maintenanceud8cPGJobNMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: policiestS6TC3Xuq5Meta?.name ?? undefined,
    path: policiestS6TC3Xuq5Meta?.path ?? "/policies",
    meta: policiestS6TC3Xuq5Meta || {},
    alias: policiestS6TC3Xuq5Meta?.alias || [],
    redirect: policiestS6TC3Xuq5Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93uCPmH7mj50Meta?.name ?? "policies-id",
    path: _91id_93uCPmH7mj50Meta?.path ?? ":id()",
    meta: _91id_93uCPmH7mj50Meta || {},
    alias: _91id_93uCPmH7mj50Meta?.alias || [],
    redirect: _91id_93uCPmH7mj50Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id].vue").then(m => m.default || m),
    children: [
  {
    name: providers_45impactedgNf0Cq2yFdMeta?.name ?? "policies-id-providers-impacted",
    path: providers_45impactedgNf0Cq2yFdMeta?.path ?? "providers-impacted",
    meta: providers_45impactedgNf0Cq2yFdMeta || {},
    alias: providers_45impactedgNf0Cq2yFdMeta?.alias || [],
    redirect: providers_45impactedgNf0Cq2yFdMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies/[id]/providers-impacted.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexfUb6sZlj7mMeta?.name ?? "policies",
    path: indexfUb6sZlj7mMeta?.path ?? "",
    meta: indexfUb6sZlj7mMeta || {},
    alias: indexfUb6sZlj7mMeta?.alias || [],
    redirect: indexfUb6sZlj7mMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/policies/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: reset_45password_45confirm7rBPdaUyyGMeta?.name ?? "reset-password-confirm",
    path: reset_45password_45confirm7rBPdaUyyGMeta?.path ?? "/reset-password-confirm",
    meta: reset_45password_45confirm7rBPdaUyyGMeta || {},
    alias: reset_45password_45confirm7rBPdaUyyGMeta?.alias || [],
    redirect: reset_45password_45confirm7rBPdaUyyGMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password-confirm.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordwFwPnE786bMeta?.name ?? "reset-password",
    path: reset_45passwordwFwPnE786bMeta?.path ?? "/reset-password",
    meta: reset_45passwordwFwPnE786bMeta || {},
    alias: reset_45passwordwFwPnE786bMeta?.alias || [],
    redirect: reset_45passwordwFwPnE786bMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: signinBNCRKvIQTKMeta?.name ?? "signin",
    path: signinBNCRKvIQTKMeta?.path ?? "/signin",
    meta: signinBNCRKvIQTKMeta || {},
    alias: signinBNCRKvIQTKMeta?.alias || [],
    redirect: signinBNCRKvIQTKMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signin.vue").then(m => m.default || m)
  },
  {
    name: signupIylKkg2SqPMeta?.name ?? undefined,
    path: signupIylKkg2SqPMeta?.path ?? "/signup",
    meta: signupIylKkg2SqPMeta || {},
    alias: signupIylKkg2SqPMeta?.alias || [],
    redirect: signupIylKkg2SqPMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signup.vue").then(m => m.default || m),
    children: [
  {
    name: confirm0KkTXJKgZ9Meta?.name ?? "signup-confirm",
    path: confirm0KkTXJKgZ9Meta?.path ?? "confirm",
    meta: confirm0KkTXJKgZ9Meta || {},
    alias: confirm0KkTXJKgZ9Meta?.alias || [],
    redirect: confirm0KkTXJKgZ9Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signup/confirm.vue").then(m => m.default || m)
  },
  {
    name: indexT5S6KNtwKnMeta?.name ?? "signup",
    path: indexT5S6KNtwKnMeta?.path ?? "",
    meta: indexT5S6KNtwKnMeta || {},
    alias: indexT5S6KNtwKnMeta?.alias || [],
    redirect: indexT5S6KNtwKnMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/signup/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: welcomeimaWIc1gaTMeta?.name ?? "welcome",
    path: welcomeimaWIc1gaTMeta?.path ?? "/welcome",
    meta: welcomeimaWIc1gaTMeta || {},
    alias: welcomeimaWIc1gaTMeta?.alias || [],
    redirect: welcomeimaWIc1gaTMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/apps/console/pages/welcome.vue").then(m => m.default || m)
  },
  {
    name: auth_45callbackvzPvcsneV7Meta?.name ?? "auth-callback",
    path: auth_45callbackvzPvcsneV7Meta?.path ?? "/auth/callback",
    meta: auth_45callbackvzPvcsneV7Meta || {},
    alias: auth_45callbackvzPvcsneV7Meta?.alias || [],
    redirect: auth_45callbackvzPvcsneV7Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-callback.vue").then(m => m.default || m)
  },
  {
    name: auth_45login2NyvXp0YlaMeta?.name ?? "auth-login",
    path: auth_45login2NyvXp0YlaMeta?.path ?? "/auth/login",
    meta: auth_45login2NyvXp0YlaMeta || {},
    alias: auth_45login2NyvXp0YlaMeta?.alias || [],
    redirect: auth_45login2NyvXp0YlaMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-login.vue").then(m => m.default || m)
  },
  {
    name: auth_45statustPEZIB9azrMeta?.name ?? "auth-status",
    path: auth_45statustPEZIB9azrMeta?.path ?? "/auth/status",
    meta: auth_45statustPEZIB9azrMeta || {},
    alias: auth_45statustPEZIB9azrMeta?.alias || [],
    redirect: auth_45statustPEZIB9azrMeta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth0/src/runtime/pages/auth-status.vue").then(m => m.default || m)
  },
  {
    name: samlR4ARVeTuD4Meta?.name ?? "AuthSaml",
    path: samlR4ARVeTuD4Meta?.path ?? "/saml",
    meta: samlR4ARVeTuD4Meta || {},
    alias: samlR4ARVeTuD4Meta?.alias || [],
    redirect: samlR4ARVeTuD4Meta?.redirect,
    component: () => import("/builds/rialtic/workflows/console-ui/packages/nuxt-auth/src/pages/saml.vue").then(m => m.default || m)
  }
]