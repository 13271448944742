<script setup lang="ts">
import { useWorkspace } from './stores/workspace'

const currentUser = useCurrentUser()
const { $auth, $auth0, $datadog, $pendo, $pwa } = useNuxtApp()
const { user } = $auth0()
const route = useRoute()
const workspace = useWorkspace()

useHead(() => ({
  title: (route.meta.title as string) || undefined,
  titleTemplate: (s) => {
    return s ? `${s} | Rialtic Console` : 'Rialtic Console'
  },
}))

watch(user, async (auth0User) => {
  if (!auth0User) {
    currentUser.value = null
    return
  }

  $auth.tokenInvalidate()

  currentUser.value = await workspace.getUser()
  if (route.name === 'signin') {
    useRouter().replace('/')
  }
})

watch(
  [currentUser, () => workspace.workspaceId],

  ([currUser]) => {
    if (!currUser) {
      return
    }
    const { id, email, workspaceRoles } = currUser

    const name = user.value
      ? `${user.value.given_name} ${user.value.family_name}`
      : `${currUser?.first_name} ${currUser?.last_name}`

    const roles =
      user.value?.['https://auth.rialtic.io/roles'] ||
      Object.keys(workspaceRoles).filter((key) =>
        key.startsWith(workspace.workspaceId),
      )

    $datadog.setUser({
      id,
      name,
      email,
      roles: roles.join(', '),
      email_domain: email.split('@')[1],
    })
    $pendo.identify({
      visitor: {
        id,
        email,
        full_name: name,
        role: roles[0] ?? '',
        roles: roles.join(', '),
      },
      account: {
        id: email.split('@')[1] || workspace.workspaceId,
        name: workspace.activeWorkspace?.name,
        rialtic_app: 'console',
        workspace_id: workspace.workspaceId,
      },
    })
  },
  {
    immediate: true,
  },
)

const getRefData = async (code: string, model: string) => {
  const { isAuthenticated, getAccessTokenSilently } = $auth0()
  const token = isAuthenticated.value
    ? await getAccessTokenSilently()
    : undefined
  const api = useRpcClient(token)
  const res = await api['ref-data'][':model'].$get({
    param: {
      model,
    },
    query: {
      search_phrase: model === 'Diagnosis' ? code.replace('.', '') : code,
    },
  })

  const data = await res.json()
  if (data?.length) {
    return data[0]
  }
  return null
}

const userPermissions = computed(() => currentUser.value?.permissions)
provide('user-permissions', userPermissions)
provide('getRefData', getRefData)
</script>

<template>
  <div class="font-poppins bg-surface-bg">
    <VitePwaManifest />
    <NuxtLoadingIndicator
      color="repeating-linear-gradient(to right,#6F7CCD 0%,#28B7F0 62%,#35CDDA 100%)"
    />
    <UiTransitionFade>
      <div
        v-show="$pwa?.needRefresh"
        class="card z-1000 bg-primary body-1 absolute bottom-4 right-4 flex items-center space-x-2 p-2 text-white"
      >
        <p class="p-2">New version available, click reload to update</p>

        <UiButton size="xs" @click="$pwa?.updateServiceWorker()">
          Reload
        </UiButton>
      </div>
    </UiTransitionFade>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>
