export const enum AuthStorage {
  accessToken = '@rialtic/accessToken',
  refreshToken = '@rialtic/refreshToken',
}

const createSetToken = (storageName: AuthStorage) => (token: string) => {
  if (!process.client) {
    return null
  }

  if (!token) {
    sessionStorage.removeItem(storageName)
    return null
  }

  sessionStorage.setItem(storageName, token)

  return token
}

const tokenManager = {
  clear: () => {
    sessionStorage.removeItem(AuthStorage.accessToken)
    sessionStorage.removeItem(AuthStorage.refreshToken)
  },
  getAccessToken: () => sessionStorage.getItem(AuthStorage.accessToken),
  setAccessToken: createSetToken(AuthStorage.accessToken),
  getRefreshToken: () => sessionStorage.getItem(AuthStorage.refreshToken),
  setRefreshToken: createSetToken(AuthStorage.refreshToken),
}

export { tokenManager }
