import { useFetch } from '#app'
import { useCookie } from '#imports'
import { useSessionStorage } from '@vueuse/core'

import { AuthStorage, tokenManager } from '../utils/tokens'

const mergeOptions = (opts: any) => {
  return {
    ...opts,
    headers: {
      ...opts?.headers,
      Authorization: `Bearer ${tokenManager.getAccessToken()}`,
    },
  }
}

export { AuthStorage, tokenManager }

export const useServerAccessToken = () =>
  useCookie<string | null>('@rialtic/accessToken', {
    default: () => null,
    httpOnly: true,
    maxAge: 900,
    ...(!process.dev
      ? {
          secure: true,
          domain: 'rialtic.app',
        }
      : {
          sameSite: 'none',
        }),
  })

export const useClientAccessToken = () =>
  useSessionStorage<string | null>('@rialtic/accessToken', null)

export const useAccessToken = () =>
  process.client ? useClientAccessToken() : useServerAccessToken()
