import { useRoute, useRouter } from '#imports'
import type { NavigationFailure, LocationQuery } from 'vue-router'

export function useUrlQueryHandler() {
  const router = useRouter()
  const route = useRoute()

  // helper methods
  const removeFromQuery = (query: any, queryParam: string, value: string) => {
    if (query?.[queryParam]?.includes('|')) {
      const paramList = (<string>query[queryParam]).split('|')
      const indexToRemove = paramList.indexOf(value)

      paramList.splice(indexToRemove, 1)
      return { ...query, [queryParam]: paramList.join('|') }
    } else return { ...query, [queryParam]: undefined }
  }

  const addToQuery = (
    query: any,
    queryParam: string,
    value: string,
    isRadio: boolean = false,
  ) => {
    if (query[queryParam] && !isRadio)
      return { ...query, [queryParam]: `${query[queryParam]}|${value}` }
    else return { ...query, [queryParam]: value }
  }

  /**
   * Adds parameter to the URL Query String via History API.
   * @param queryParam query parameter being added or added to
   * @param value value of query parameter
   * @param isRadio if query parameter belongs to a radio button filter
   */
  const addParamToHistory = (
    queryParam: string,
    value: string,
    isRadio: boolean = false,
  ): void => {
    const query = addToQuery(
      history.state.query || {},
      queryParam,
      value,
      isRadio,
    )
    const params = Object.keys(query)
    let queryString = ''

    if (params.length) {
      params.forEach((param) => {
        queryString += queryString
          ? `&${param}=${query[param]}`
          : `${param}=${query[param]}`
      })

      history.replaceState({ query }, '', `${location.pathname}?${queryString}`)
    }
  }

  /**
   * Removes all query params and restores URL back to pathname
   */
  const clearHistory = (): void => {
    history.replaceState({ query: {} }, '', `${location.pathname}`)
  }

  /**
   * Clears provided query parameter from URL via History API.
   * @param queryParam Query parameter to clear from URL
   */
  const clearQueryParamFromHistory = (queryParam: string): void => {
    const query = history.state.query || {}
    let queryString = ''

    if (query[queryParam]) {
      delete query[queryParam]
    }

    const params = Object.keys(query)

    if (params.length) {
      params.forEach((param) => {
        queryString += queryString
          ? `&${param}=${query[param]}`
          : `${param}=${query[param]}`
      })

      history.replaceState(
        { query },
        '',
        `${
          queryString ? location.pathname + '?' : location.pathname
        }${queryString}`,
      )
    } else {
      history.replaceState({ query }, '', location.pathname)
    }
  }

  /**
   * Removes parameter from the URL Query String via History API.
   * @param queryParam query parameter being removed or removed from
   * @param value value of query parameter
   */
  const removeParamFromHistory = (queryParam: string, value: string): void => {
    const query = removeFromQuery(history.state.query || {}, queryParam, value)
    const params = Object.keys(query)
    let queryString = ''

    // cleanup cleared out params
    params.forEach((param) => {
      if (!query[param]) delete query[param]
    })

    if (params.length) {
      params.forEach((param) => {
        if (query[param])
          queryString += queryString
            ? `&${param}=${query[param]}`
            : `${param}=${query[param]}`
      })

      history.replaceState(
        { query },
        '',
        `${
          queryString ? location.pathname + '?' : location.pathname
        }${queryString}`,
      )
    } else {
      history.replaceState({ query }, '', location.pathname)
    }
  }

  /**
   * Adds parameter to the URL Query String via Vue Router.
   * @param queryParam query parameter being added or added to
   * @param value value of query parameter
   * @param isRadio if query parameter belongs to a radio button filter
   */
  const addParamToUrlQuery = async (
    queryParam: string,
    value: string,
    isRadio: boolean = false,
  ): Promise<void | NavigationFailure> => {
    return router.replace({
      path: route.path,
      query: addToQuery(route.query, queryParam, value, isRadio),
    })
  }

  /**
   * Clears provided query parameter from URL via Vue Router.
   * @param queryParam Query parameter to clear from URL
   */
  const clearQueryParam = async (
    queryParam: string,
  ): Promise<void | NavigationFailure> => {
    return router.replace({
      path: route.path,
      query: { ...route.query, [queryParam]: undefined },
    })
  }

  const mergeAndUpdateQuery = (routerQuery: LocationQuery, historyQuery: any) => {
    const query = {...routerQuery, ...historyQuery}
    const params = Object.keys(query)
    let queryString = ''

    if (params.length) {
      params.forEach((param) => {
        queryString += queryString
          ? `&${param}=${query[param]}`
          : `${param}=${query[param]}`
      })
    }

      history.replaceState({ query }, '', `${location.pathname}?${queryString}`)
  }

  /**
   * Removes parameter from the URL Query String via Vue Router.
   * @param queryParam query parameter being removed or removed from
   * @param value value of query parameter
   */
  const removeParamFromUrlQuery = (
    queryParam: string,
    value: string,
  ): Promise<void | NavigationFailure> => {
    return router.replace({
      path: route.path,
      query: removeFromQuery(route.query, queryParam, value),
    })
  }

  return {
    addParamToHistory,
    clearHistory,
    removeParamFromHistory,
    clearQueryParamFromHistory,
    addParamToUrlQuery,
    clearQueryParam,
    removeParamFromUrlQuery,
    mergeAndUpdateQuery,
  }
}
