import type { Api } from '@rialtic/api'
import { defu } from 'defu'
import { clear, del, get, set } from 'idb-keyval'
import { acceptHMRUpdate, defineStore } from 'pinia'
import { ALL_LOB } from '~/constants'
import type { ConnectorAnalyticsState } from '~/types'
import { useWorkspace } from './workspace'

interface PolicyInsightCounts {
  id: string
  insight_count: number
  savings: number
  impacted_providers: number
  claim_count_with_insights_total: number
  counts_by_insight_mode?: {
    Active: {
      claim_count_with_insights: number
      insight_count: number
      savings_opportunity_cents: number
    }
    Observation: {
      claim_count_with_insights: number
      insight_count: number
      savings_opportunity_cents: number
    }
  }
  insight_count_total: number
  provider_impact?: {
    insights_count_median: number
    impacted_providers: number
    savings_opportunity_median: number
    top_5_by_insights_count: {
      tin: string
      name: string
      insights_count: number
    }[]
    top_5_by_savings_opportunity: {
      tin: string
      name: string
      savings_opportunity: number
    }[]
  }
  savings_opportunity_cents: number
}

interface ChartInsightItem {
  id: string
  name: string
  countActive: number
  countObservation: number
  claim_count_with_insights: number
  /* format "0.01" */
  rateActive: string
  /* format "0.01" */
  rateObservation: string
  savingsActive: number
  savingsObservation: number
}

type InsightItem = Api.ConnectorInsightCounts & { lastRefreshedAt?: number }

export type AggregateState = Omit<
  InsightItem,
  | 'connector_id'
  | 'date_range'
  | 'policies'
  | 'editTypeInsights'
  | 'topicInsights'
>
export const defaultAggregate: AggregateState = {
  claimTotal: 0,
  claimVolume: 0,
  countsActive: {
    claim_count_with_insights: 0,
    insight_count: 0,
    savings_opportunity_cents: 0,
  },
  countsObservation: {
    claim_count_with_insights: 0,
    insight_count: 0,
    savings_opportunity_cents: 0,
  },
  claims_with_recommendations_count: 0,
  claims_with_reporting_insights_count: 0,
  insightsActive: 0,
  insightsObservation: 0,
  rateActive: '0',
  rateObservation: '0',
  savingsActive: 0,
  savingsObservation: 0,
  savingsTotal: 0,
  hasPolicyCounts: false,
}

const getDefaultInsightCounts = (
  connector_id: string,
): ConnectorAnalyticsState => ({
  connector_id,
  aggregate: defaultAggregate,
  error: null,
  lastRefreshedAt: null,
  loading: false,
  policies: [],
  editTypeInsights: [],
  topicInsights: [],
  updated: Date.now(),
})

const mapChartInsightItem = (x: ChartInsightItem) => {
  let savingsObj = {
    savingsActive: x.savingsActive / 100,
    savingsObservation: x.savingsObservation / 100,
  }
  return { ...x, ...savingsObj }
}

export const useConnectorAnalytics = defineStore('connector-analytics', {
  state: () => {
    return {
      connectors: new Map<string, ConnectorAnalyticsState>(),
      isAggregateLoading: false,
      lastRefreshedAt: 0,
      nextRefreshEstimate: Date.now() + 1000 * 60 * 60,
      requestsInFlight: new Set<string>(),
      retryAfterTimestamp: Infinity,
      syncConnectorAfter: Infinity,
    }
  },

  getters: {
    chartEditTypeInsights: (state) => (connectorId: string) =>
      state.connectors
        .get(connectorId)
        ?.editTypeInsights.map(mapChartInsightItem) || [],

    chartTopicInsights: (state) => (connectorId: string) =>
      state.connectors
        .get(connectorId)
        ?.topicInsights.map(mapChartInsightItem) || [],

    displaySavings: (state) => (connectorId: string) => {
      const results = {
        savingsActive: {
          label: 'Active',
          value: ['-', '-'],
        },
        savingsObserving: {
          label: 'Observation',
          value: ['-', '-'],
        },
      }

      const aggregate =
        state.connectors.get(connectorId)?.aggregate || defaultAggregate

      results.savingsActive.value = [
        formatCents(aggregate.savingsActive, NumeralFormats.DASH_DOLLARS),
        formatRate(aggregate.savingsActive / aggregate.claimVolume),
      ]
      results.savingsObserving.value = [
        formatCents(aggregate?.savingsObservation, NumeralFormats.DASH_DOLLARS),
        formatRate(aggregate.savingsObservation / aggregate.claimVolume),
      ]

      return results
    },

    displayClaimsStats: (state) => (connectorId: string) => {
      const results = {
        claimVolume: {
          label: 'Claim volume',
          value: '-',
        },
        totalClaims: {
          label: 'Total claims',
          value: '-',
        },
      }

      const aggregate =
        state.connectors.get(connectorId)?.aggregate || defaultAggregate

      results.claimVolume.value =
        aggregate.claimVolume != null
          ? formatCents(aggregate.claimVolume, NumeralFormats.DASH_DOLLARS)
          : '-'

      results.totalClaims.value =
        aggregate.claimTotal != null
          ? formatCount(aggregate.claimTotal, NumeralFormats.CLAIM_TOTAL)
          : '-'

      return results
    },

    displayInsightStats: (state) => (connectorId: string) => {
      const aggregate =
        state.connectors.get(connectorId)?.aggregate || defaultAggregate

      return {
        rateActive: {
          label: 'Active',
          value: formatRate(aggregate.rateActive),
        },
        rateObservation: {
          label: 'Observation',
          value: formatRate(aggregate.rateObservation),
        },
      }
    },

    loading: (state) => state.requestsInFlight.size > 0,

    policyInsightCounts: (state) => (connectorId: string) => {
      const map = new Map<string, PolicyInsightCounts>()

      const policies = state.connectors.get(connectorId)?.policies || []

      policies.forEach((policyData) => {
        map.set(policyData.id, {
          ...policyData,
          id: policyData.id,
          insight_count: policyData.insight_count_total || 0,
          savings: policyData?.savings_opportunity_cents,
          impacted_providers:
            policyData?.provider_impact?.impacted_providers || 0,
        })
      })

      return map
    },
  },

  actions: {
    clearCacheDb() {
      return clear()
    },

    clearCacheDbByKey(workspaceId: string, connectorId: string) {
      return del(`analytics_aggregate:${workspaceId}:${connectorId}`)
    },

    async fetchAnalyticsAggregate(
      workspaceId: string,
      connectorId: string,
    ): Promise<InsightItem | null> {
      if (connectorId === ALL_LOB) {
        return null
      }
      const { $auth, $auth0, $datadog } = useNuxtApp()
      const workersApi = useWorkersApi()
      const workspace = useWorkspace()
      const { isAuthenticated } = $auth0()

      try {
        if (!isAuthenticated.value) await $auth.checkAndRefresh()
        await workspace.waitForReady()
      } catch (error) {
        return null
      }

      if (!workspace.muxIsConfigured || workspace.underMaintenance) {
        return null
      }

      try {
        const data = await workersApi.fetch<InsightItem>(
          `/hiro-insights/${workspaceId}/connector/${connectorId}/insights/counts`,
          {
            params: {
              ...workspace.muxFilters,
              connector_id: connectorId,
              insight_mode: ['Active', 'Observation'],
              insight_type: [
                'Claim Line Partially Payable',
                'Claim Line Not Payable',
                'Claim Not Payable',
                'Recode Claim Line',
              ],
            },
          },
        )
        return data
      } catch (e) {
        $datadog.addAction('analytics_aggregate_error', {
          workspaceId,
          connectorId,
          error: e instanceof Error ? e.message : e,
        })
        return null
      }
    },

    async getAnalyticsAggregate(
      workspaceId: string,
      connectorId: string,
    ): Promise<void> {
      if (!workspaceId || !connectorId || connectorId === ALL_LOB) {
        return
      }
      this.isAggregateLoading = true
      const workspace = useWorkspace()
      const { $datadog } = useNuxtApp()

      const inflightKey = `${connectorId}:${workspace.muxFilters.start_date}${workspace.muxFilters.end_date}`

      if (this.requestsInFlight.has(inflightKey)) {
        return
      }

      this.requestsInFlight.add(inflightKey)

      try {
        const KEY = `analytics_aggregate:${workspaceId}:${connectorId}`

        if (!workspace.muxIsConfigured) {
          this.setAnalyticsAggregate(connectorId, null)
          await workspace.waitForReady(3000)
        }

        if (process.client) {
          const cachedData = await get<InsightItem | null>(KEY)
          this.setAnalyticsAggregate(connectorId, cachedData)
        }

        const data = await this.fetchAnalyticsAggregate(
          workspaceId,
          connectorId,
        )

        if (data) this.setAnalyticsAggregate(connectorId, data)

        if (process.client && data) {
          try {
            await set(KEY, data)
          } catch (error) {}
        }
      } catch (error) {
        $datadog.addError(error)
      } finally {
        setTimeout(() => {
          this.requestsInFlight.delete(inflightKey)
        }, 2000)
        this.isAggregateLoading = false
      }
    },

    async refreshConnectorAnalytics(workspaceId: string, connectorId: string) {
      if (!workspaceId || !connectorId) {
        return
      }
      const { $auth, $auth0, $datadog } = useNuxtApp()
      const { getAccessTokenSilently } = $auth0()

      try {
        const token = $auth.refreshToken
          ? await $auth.checkAndRefresh()
          : await getAccessTokenSilently()
        const client = useRpcClient(token)

        if (!$auth.refreshToken) {
          this.$reset()
          return null
        }

        if (!$auth.accessToken) {
          await $auth.checkAndRefresh()
        }

        const res = await client.event.connector[':connectorId'].refresh.$get({
          param: {
            connectorId,
          },
          query: {
            workspace_id: workspaceId,
          },
        })

        const data = await res.json()

        this.$patch({
          retryAfterTimestamp: data?.retryAfterTimestamp || Infinity,
          lastRefreshedAt: data?.lastRefreshedAt || 0,
          nextRefreshEstimate: data?.nextRefreshEstimate || 0,
          syncConnectorAfter: data?.nextRefreshEstimate || Infinity,
        })
      } catch (error) {
        $datadog.addError(error)
        this.lastRefreshedAt = Infinity
      }
    },

    setAnalyticsAggregate(
      connectorId: string,
      data: InsightItem | null | undefined,
    ) {
      const insightCounts = data || getDefaultInsightCounts(connectorId)

      const { policies, editTypeInsights, topicInsights, ...aggregate } =
        insightCounts

      this.connectors.set(connectorId, {
        connector_id: connectorId,
        aggregate: defu(aggregate, defaultAggregate),
        editTypeInsights,
        error: null,
        lastRefreshedAt: insightCounts.lastRefreshedAt || null,
        loading: false,
        policies,
        topicInsights,
        updated: Date.now(),
      })
    },

    setLoadingState(connectorId: string, loading = false) {
      this.connectors.set(connectorId, {
        ...(this.connectors.get(connectorId) ||
          getDefaultInsightCounts(connectorId)),
        loading,
      })
    },

    updateConnectorAnalytics(
      connectorId: string,
      updates: Partial<ConnectorAnalyticsState>,
    ) {
      const currentState = this.connectors.get(connectorId)

      if (!currentState) {
        return
      }
      this.connectors.set(connectorId, {
        ...currentState,
        ...updates,
        updated: Date.now(),
      })
    },
  },
})

if (import.meta.hot)
  import.meta.hot.accept(
    acceptHMRUpdate(useConnectorAnalytics, import.meta.hot),
  )
